<template>
  <div class="basic" v-if="user.sex == 'M' || user.sex === undefined">

    <v-card flat class="pa-4 ma-3 red--text font-weight-bold">
      탄수화물 사이클링은 남성, 고급자 전용입니다.
    </v-card>

    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col cols="12">
        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col md="3" cols="6" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">체중</div>
              <div class="recommended">{{ weight_init }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">체지방률</div>
              <div class="recommended">{{ fatrate_init }}</div>
              <div class="unit">%</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">골격근량</div>
              <div class="recommended">{{ muscle }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">하루 칼로리 소비량</div>
              <div class="recommended">{{ calory_calculated.format() }}</div>
              <div class="unit">Kcal</div>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">

          <v-row dense>

            <v-col cols="12" class="subtitle font-weight-bold">탄수화물 사이클링</v-col>

            <v-col md="12" cols="12" v-for="(each, i) in cycling" :key="i">
              <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                <v-row dense>
                  <v-col cols="1" class="subtitle-2 font-weight-bold" align="left">{{ each.week }}</v-col>
                  <v-col md="6" cols="11" class="subtitle-2" align="left">{{ each.workout }}</v-col>
                  <v-col md="2" class="body-2" align="right">
                    <div>칼로리 : {{ each.calory.format() }}Kcal</div>
                    <v-btn text @click="import_to_food(each)" small>
                      <v-icon small>restaurant</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="1" cols="2" align="right" class="body-2" style="border-bottom: 1px solid #70AD47">{{ each.carb.format() }} kcal</v-col>
                  <v-col md="1" cols="2" align="right" class="body-2" style="border-bottom: 1px solid #4472C7">{{ each.protein.format() }} kcal</v-col>
                  <v-col md="1" cols="2" align="right" class="body-2" style="border-bottom: 1px solid #ED7D31">{{ each.fat.format() }} kcal</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row>
      <v-col cols="12">
        <v-row class="align-center justify-center">
          <v-card flat color="#FBDE44FF">
            <v-card-title class="subtitle-2">
              탄수화물 사이클링은 남성 전용입니다.
            </v-card-title>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

export default {
  components: {
    // UserLoading,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      userloading: "userloading",
      dietstatus: "dietstatus",
      loaded: "loaded",

      tdee: "tdee",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
  },
  data: () => {
    return {
      weight_init: 0,
      fatrate_init: 0,
      muscle: 0,
      cycling: [],
      calory_calculated: 0,
      week: ['월', '화', '수', '목', '금', '토', '일'],
      calory_diff: [ -600, -700, 500, -600, -700, 500, -500 ],
      carb_rate: [ 0.2, 0.1, 0.7, 0.2, 0.1, 0.7, 0.3 ],
      rules: {
        limited: value => value < 90 || '90 미만을 입력하세요' ,
        over70: value => value >= 70 || '70 이상 값을 입력하세요.',
        over60: value => value >= 60 || '60 이상 값을 입력하세요.',
      }
    }
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info();
      }
    },
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_info();
    }
  },
  methods: {
    reload_info: async function() {

      if ( firebase.auth().currentUser ) {
        // load initial data
        const id_token = await firebase.auth().currentUser.getIdToken();
        const loading_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/loading', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        const set_init = async () => {
          if ( this.data.weight == 0 ) {
            setTimeout(() => {
              set_init();
              this.reload_info();
            }, 1000);
          } else {
            this.weight_init = this.data.weight;
            this.fatrate_init = this.data.fat;
            this.muscle = this.data.muscle;
            this.calory_calculated = this.tdee;
          }
        }

        if ( loading_.data.length > 0 ) {
          // already data
          let latest_ = loading_.data[loading_.data.length-1];

          if ( latest_.date_f != "" ) {
            await set_init();
          } else {
            this.weight_init = latest_.weight;
            this.fatrate_init = latest_.fatrate;
            this.muscle = latest_.muscle;
            this.calory_calculated = latest_.calculated_calory;
            this.date = latest_.date;
          }
        } else {
          await set_init();
        }

        if ( this.dietstatus == 1 ) {
          const id_token = await firebase.auth().currentUser.getIdToken();
          const diet_ = await axios.get(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
            params: { email: this.user.data.email },
            headers: {'id_token': id_token},
          });

          if ( diet_.data.length > 0 ) {
            let latest_diet_ = diet_.data[diet_.data.length-1];

            if ( latest_diet_.date_f == "" ) {
              // this.calory_calculated = latest_diet_.calculated_calory;

              let calculated = false;

              let today_ = this.$moment().format("YYYY-MM-DD");

              let diff_ = 0;
              const id_token = await firebase.auth().currentUser.getIdToken();
              const cheat_ = await axios.get(
                api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating', {
                params: { email: this.user.data.email, date: latest_diet_.date },
                headers: {'id_token': id_token},
              });

              if ( cheat_.data.length > 0  ) {

                let week_sday = latest_diet_.date;
                let week_eday = today_;

                //
                for ( let each of cheat_.data ) {

                  if ( week_sday <= each.date && week_eday > each.date ) {
                    diff_++;
                  }

                }
                //

                let latest_cheat_ = cheat_.data[cheat_.data.length-1];

                if ( latest_cheat_.date == today_) {
                  this.calory_calculated = latest_cheat_.calculated_calory;
                  calculated = true;
                }
              }

              if ( !calculated ) {

                let weeks = this.$moment().diff(this.$moment(latest_diet_.date, "YYYY-MM-DD"), 'weeks');

                this.calory_calculated = latest_diet_.calculated_calory - latest_diet_.calory_delta_init - latest_diet_.calory_delta_weekly*(weeks - diff_);
              }

            }

          }
        }

        await this.calc_cycling();

      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    calc_cycling: async function() {

      this.cycling = [];

      for ( let i = 0; i < 7 ; i++ ) {
        let t_ = {
          week: this.week[i],
          calory_diff: this.calory_diff[i],
          calory: this.calory_calculated + this.calory_diff[i],
          carb: this.calory_calculated * this.carb_rate[i],
          protein: this.weight_init * this.data.protein_multiplier * 4,
          carb_rate: this.carb_rate[i] * 100,
        }
        t_['fat'] = t_.calory - t_.carb - t_.protein;

        switch(i) {
        case 0:
          t_['workout'] = "중간 볼륨 웨이트 + 60~70% 심박수 운동";
          t_['meal'] = "저탄수";
          break;
        case 1:
          t_['workout'] = "낮은 볼륨 웨이트 + 60~70% 심박수 운동";
          t_['meal'] = "저탄수";
          break;
        case 2:
          t_['workout'] = "HIIT 4분운동 2분휴식 10회 +고강도 웨이트";
          t_['meal'] = "고탄수";
          break;
        case 3:
          t_['workout'] = "중간 볼륨 웨이트 + 60~70% 심박수 운동";
          t_['meal'] = "저탄수";
          break;
        case 4:
          t_['workout'] = "낮은 볼륨 웨이트 + 60~70% 심박수 운동";
          t_['meal'] = "저탄수";
          break;
        case 5:
          t_['workout'] = "HIIT 4분운동 2분휴식 10회 + 고강도 웨이트";
          t_['meal'] = "고탄수";
          break;
        case 6:
          t_['workout'] = "휴식 or 60~70% 심박수 운동";
          t_['meal'] = "저탄수";
          break;
        }

        t_['calory'] = Math.round(t_['calory']/10)*10;
        t_['carb'] = Math.round(t_['carb']/10)*10;
        t_['protein'] = Math.round(t_['protein']/10)*10;
        t_['fat'] = Math.round(t_['fat']/10)*10;

        this.cycling.push(t_);
      }
    },
    import_to_food: async function(info) {
      if ( this.dietstatus == 1 || this.dietstatus == 2 ) {
        alert("다이어트나 유지어트중엔 식단으로 정보를 보낼 수 없습니다.");
        return;
      }

      if ( confirm("해당 정보를 식단으로 읽어들이겠습니까?") ) {

        this.data.calory_delta = info.calory_diff;
        this.data.calculated_calory = info.calory;
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cycling', {
          email: this.user.data.email,
          weight: this.weight_init,
          fatrate: this.fatrate_init,
          muscle: this.muscle,
          tdee: this.tdee,
          calory_diff: info.calory_diff,
          calory: info.calory,
          carb: info.carb,
          protein: info.protein,
          fat: info.fat,
        }, {
          headers: {'id_token': id_token},
        });
      }

    },
  }

}
</script>

<style scoped>


</style>
